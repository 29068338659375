import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import { Section } from '../../components/Core'
import PostCard from '../../components/PostCard'

import imgGitHub from '../../assets/image/png/company-github.png'
import imgBikeIndex from '../../assets/image/png/company-bikeindex.png'
import imgVive from '../../assets/image/png/company-vive.png'
import imgShape from '../../assets/image/png/company-shape.png'
import imgPetco from '../../assets/image/png/company-petco.png'
import imgQuip from '../../assets/image/svg/company-quip.svg'

const ProjectList = () => (
  <>
    {/* <!-- Feature section --> */}
    <Section className='position-relative'>
      <Container>
        <Row className='align-items-center justify-content-center'>
          <Col lg='4' className='mb-5'>
            <PostCard
              img={imgQuip}
              title='rewards point engine'
              date={2021}
              linkTo='/coming-soon'
              mainImageStyles={{ paddingTop: '20px' }}
            ></PostCard>
          </Col>
          <Col lg='4' className='mb-5'>
            <PostCard
              img={imgBikeIndex}
              title='platform internationalization'
              date={2019}
              linkTo='/coming-soon'
            ></PostCard>
          </Col>
          <Col lg='4' className='mb-5'>
            <PostCard
              img={imgVive}
              title='cost analytics dashboard'
              date={2016}
              linkTo='/coming-soon'
            ></PostCard>
          </Col>
        </Row>
        <Row className='align-items-center justify-content-center'>
          <Col lg='4' className='mb-5'>
            <PostCard
              img={imgPetco}
              title='ETL data migration'
              date={2018}
              linkTo='/coming-soon'
            ></PostCard>
          </Col>
          <Col lg='4' className='mb-5'>
            <PostCard
              img={imgShape}
              title='MVP implementation'
              date={2017}
              linkTo='/coming-soon'
            ></PostCard>
          </Col>
          <Col lg='4' className='mb-5'>
            <PostCard
              img={imgGitHub}
              title='2FA enforcement for organizations'
              date={2016}
              linkTo='/coming-soon'
            ></PostCard>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
)

export default ProjectList
