import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'

import PageWrapper from '../components/PageWrapper'
import { Title, Section, Box } from '../components/Core'

import ProjectList from '../sections/projects/ProjectList'

import imgBackground from '../assets/image/jpeg/cellist.jpg'

const BoxStyled = styled(Box)`
  background-image: url(${imgBackground});
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
`

const ShadowBox = styled(BoxStyled)`
  background: rgba(0, 0, 0, 0.5);
`

const ProjectsPage = () => {
  return (
    <>
      <PageWrapper headerDark footerDark currentPage='projects'>
        <BoxStyled>
          <ShadowBox>
            <Section className='pb-0'>
              <Container>
                <Row className='justify-content-center text-center'>
                  <Col lg='6'>
                    <Title color='light'>projects</Title>
                  </Col>
                </Row>
              </Container>
              <ProjectList />
            </Section>
          </ShadowBox>
        </BoxStyled>
      </PageWrapper>
    </>
  )
}
export default ProjectsPage
