import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Title, Box, Text, Span } from '../Core'
import { device } from '../../utils'

const Card = styled(Box)`
  transition: 0.4s;
  overflow: hidden;
`

const LinkStyled = styled(Link)`
  color: ${({ theme }) => theme.colors.light};

  &:visited {
    color: ${({ theme }) => theme.colors.primary};
  }

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.accent};
  }
`

const ImageContainerHorizontal = styled(Box)`
  overflow: hidden;
  position: relative;
  width: 100%;

  @media ${device.md} {
    width: 100%;
    min-width: 350px;
    max-width: 350px;
  }
  @media ${device.lg} {
    min-width: 265px;
  }
  @media ${device.xl} {
    min-width: 350px;
    max-width: 350px;
  }
`

const MainImage = styled.img`
  max-height: 200px;
  max-width: 200px;
  margin: auto;
  padding: 0;
`

const BrandImage = styled(Box)`
  position: absolute;
  bottom: 28px;
  left: 30px;
  border-radius: 8px;
  border: 1px solid #eae9f2;
  overflow: hidden;
`

const CardText = styled(Box)`
  font-weight: 300;
  letter-spacing: 2pt;
  padding: 0;
`

const TitleStyled = styled(Title)`
  letter-spacing: 0.5pt;
  line-spacing: 1;
  font-weight: 200;
  text-align: center;
  transition: 0.3s;
`

const PostCard = ({
  children,
  date,
  horizontal = false,
  img,
  imgBrand,
  linkTo,
  mainImageStyles,
  readMore,
  title,
  ...rest
}) => (
  <Card
    className={horizontal ? 'd-flex flex-column flex-md-row' : ''}
    {...rest}
  >
    {horizontal ? (
      <ImageContainerHorizontal>
        <LinkStyled to={linkTo} className='w-100 h-100 d-flex'>
          <img src={img} alt='' className='w-100 img-fluid' />
          {imgBrand && (
            <BrandImage>
              <MainImage src={imgBrand} className='img-fluid' />
            </BrandImage>
          )}
        </LinkStyled>
      </ImageContainerHorizontal>
    ) : (
      <Box className='position-relative' style={{ height: '150px' }}>
        <LinkStyled to={linkTo} className='w-100 h-100 d-flex'>
          <MainImage
            src={img}
            className='w-100 img-fluid'
            style={mainImageStyles}
          />
          {imgBrand && (
            <BrandImage>
              <img src={imgBrand} alt='' className='img-fluid' />
            </BrandImage>
          )}
        </LinkStyled>
      </Box>
    )}

    <CardText>
      <LinkStyled to={linkTo}>
        <TitleStyled variant='card' mb='16px' color='light'>
          {title}
          <br />
          {date}
        </TitleStyled>
      </LinkStyled>
      <Text fontSize={2} lineHeight={1.75} mb='16px' color='light'>
        {children}
      </Text>
      {readMore && (
        <Box>
          <LinkStyled to={linkTo}>
            <Span color='primary'>Continue Reading</Span>
          </LinkStyled>
        </Box>
      )}
    </CardText>
  </Card>
)

export default PostCard
